import React, { useState } from 'react'
import { Link } from 'gatsby'

const Navbar = ({ location }) => {
  const [active, setActive] = useState(false)
  const [navBarActiveClass, setNavBarActiveClass] = useState('')

  const toggleHamburger = () => {
    // toggle the active boolean in the state
    if (!active) {
      setActive(true)
      setNavBarActiveClass('is-active')
      return
    }
    setActive(false)
    setNavBarActiveClass('')
  }

  return (
    <div className="navbar is-primary">
      <div className="container">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            Home
          </Link>

          <div
            className={`navbar-burger ${navBarActiveClass}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbar"
            onClick={() => toggleHamburger()}
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </div>
        </div>
        <div className={`navbar-menu ${navBarActiveClass}`} id="navbar">
          <div className="navbar-start">
            <Link className={`navbar-item ${location.pathname === '/info' ? 'is-active' : ''}`} to="/info">
              More Info
            </Link>
            <Link className={`navbar-item ${location.pathname === '/about' ? 'is-active' : ''}`} to="/about">
              About
            </Link>
            <Link className={`navbar-item ${location.pathname === '/contact' ? 'is-active' : ''}`} to="/contact">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar

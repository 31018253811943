import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Navbar from './navbar'

import '../css/all-css.sass'

const Layout = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
          subheading
        }
      }
    }
  `)

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <header className="hero is-primary">
        <div className="hero-body">
          <div className="container">
            <div className="title is-1" style={{ textTransform: 'uppercase' }}>
              {data.site.siteMetadata.title}
            </div>
            <div className="subtitle" style={{ textTransform: 'lowercase' }}>
              {data.site.siteMetadata.subheading}
            </div>
          </div>
        </div>
      </header>
      <Navbar location={location} />
      <main style={{ flexGrow: 1 }}>
        <div className="container">{children}</div>
      </main>
      <footer className="footer has-text-centered">
        © {new Date().getFullYear()}, Built by
        {` `}
        <a href="https://buildsearchtech.com/">Build and Search Technology</a>
      </footer>
    </div>
  )
}

export default Layout

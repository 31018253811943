import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '../components/layout'

export default function Home({
  data: {
    mdx: {
      frontmatter: { heading, subheading },
      body,
    },
  },
  location,
}) {
  return (
    <Layout location={location}>
      <div className="section">
        <h1 className="title is-1">{heading}</h1>
        <div className="subtitle">{subheading}</div>
      </div>
      <div>
        <MDXRenderer>{body}</MDXRenderer>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Page($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        description
        heading
        subheading
      }
    }
  }
`
